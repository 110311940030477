<template>
  <div class="iframe">
    <iframe src="http://us.cafebonappetit.io/baweb/main" frameborder="0"></iframe>
    <!--<iframe src="https://eventsbythermofisher.com/digital/login" frameborder="0"></iframe>-->
  </div>
</template>

<script>
export default {
  name: "iframe",
  data() {
    return {}
  },
  methods: {}
}
</script>

<style type="text/css" scoped>

html, body {
  margin: 0px 0px;
  width: 100%;
  height: 100%;
}
.iframe{
  height: 100%;
}
iframe {
  margin: 0px 0px;
  width: 100%;
  height: 100%;
}

</style>
